<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadUsers(true)" />
                </div>
            </div>
            <el-table :data="users.data" class="mt-2">
                <el-table-column prop="phone" label="注册登录手机号" sortable />
                <el-table-column prop="companyName" label="水发公司" sortable />
                <el-table-column prop="name" label="姓名" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.id" target="_blank">
                            {{scope.row.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="idCard" label="身份证号" sortable />
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showAuditUserDialog(scope.row,true)">审核</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="users.totalRecords"
                :current-page="usersPagination.currentPage" :page-size="usersPagination.pageSize" class="mt-3"
                @current-change="usersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="auditUserDialogVisible" title="审核" :close-on-click-modal="false" width="960px"
            top="0px">
            <iframe v-if="auditUserDialogVisible" :src="'/#/registered-user/detail?id='+user.id" frameborder="0"
                class="d-block border border-primary rounded w-100" style="height: calc(100vh - 260px);"></iframe>

            <el-form>
                <el-form-item label="审核备注">
                    <el-input v-model="auditRemark" placeholder="审核备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="success" @click="auditUser(true)">审核通过</el-button>
                <el-button type="danger" @click="auditUser(false)">审核退回</el-button>
                <el-button @click="auditUserDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                users: {},
                usersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                user: {},
                auditRemark: null,
                auditUserDialogVisible: false,
            };
        },
        methods: {
            async loadUsers(resetPage = false) {
                if (resetPage) {
                    this.usersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/RegisteredUser/GetIndividualUsers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        authenticationStatus: 2,
                        key: this.key,
                        pageIndex: this.usersPagination.currentPage - 1,
                        pageSize: this.usersPagination.pageSize,
                    }
                });
                this.users = response.data;
            },
            usersPaginationCurrentChange(page) {
                this.usersPagination.currentPage = page;
                this.loadUsers();
            },
            showAuditUserDialog(user) {
                this.user = JSON.parse(JSON.stringify(user));
                this.auditRemark = null;
                this.auditUserDialogVisible = true;
            },
            async auditUser(isPass) {
                if (!confirm(`确定要审核${isPass ? '通过' : '退回'}吗？`)) {
                    return;
                }

                await this.$axios.post('/api/RegisteredUser/AuditIndividualUserAuthentication', {
                    id: this.user.id,
                    isPass: isPass,
                    auditRemark: this.auditRemark,
                });
                this.auditUserDialogVisible = false;
                this.loadUsers();
            },
        },
        created() {
            this.loadUsers();
        },
    };
</script>